.dropDownContainer {
  display: flex;
  width: 200px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .timeSelect {
    border: none;
    outline: none;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0 10px 0 20px;
    background-color: transparent;
  }

  .arrowDown {
    transform: rotate(90deg);
    position: absolute;
    right: 10px;
  }
}

.darkContainer {
  background-color: #6e757c !important;

  .timeSelect {
    color: #fff !important;
  }
}

@media screen and (max-width: 768px) {
  .dropDownContainer {
    max-width: 139px;
  }
}