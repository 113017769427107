.tableContainer {
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  tr {
    width: 100%;
    justify-content: space-between;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
  .colorTr {
    background-color: #fff;
  }
  tbody {
    tr {
      &:hover {
        background-color: #f8f9fa;
      }
    }
  }
  .tr {
    width: 100%;
    height: 68px;
    align-items: center;

    .th {
      width: auto;
      font-family: "Nunito Semibold";
      font-size: 14px;
      color: #000;

      &:first-child {
        padding-left: 50px;
      }
      &:last-child {
         padding-right: 50px;
        text-align: center;
      }
    }
    // .longThUserTable {
    //   width: 40%;
    // }
    .longTh {
      width: 55%;
    }
    .rdTd {
      color: red !important;
    }
    .td {
      font-family: "Nunito Regular";
      font-size: 14px;
      color: #767676;

      .checkBox {
        cursor: pointer;

        .checkDiv {
          display: "flex";
          background: #ffd274;
          width: 100%;
          height: 100%;
          border-radius: 2px;
        }
      }
      &:first-child {
        padding-left: 50px;
      }
      &:last-child {
        // padding-right: 50px;
        text-align: center;
      }

      .cardDropDowns {
        // display: flex;
        align-items: center;

        flex-wrap: wrap;
        //height: 100px;
        column-gap: 40%;
        justify-content: flex-start;
        margin-left: 50px;
        margin-right: 50px;
      }
      .textAreaContainer {
        width: 100%;
        padding-right: 50px;
        position: relative;

        .placeholder {
          position: absolute;
          display: flex;
          align-items: center;
          left: 70px;
          top: 20px;
          font-family: "Nunito Regular";
          color: #7b7b7b;
          column-gap: 15px;
        }

        textarea {
          width: 100%;
          height: 100px;
          outline: none;
          border: 1px solid #dedede;
          font-family: "Nunito Regular";
          font-size: 16px;
          color: #7b7b7b;
          border-radius: 5px;
          padding: 20px;
          resize: none;
          transition: all 0.3s ease-in-out;

          &:focus {
            border: 1px solid #3658cc;
            background-color: #f8f9fa;
          }
        }
      }
      .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 20px;
        padding: 20px 0;
        margin-top: 5px;
      }
    }
    .tdLong {
      width: 60%;
    }
  }
}
@media screen and (max-width: 768px) {
  .tableContainer {
    overflow-x: overlay;

    table {
      border-spacing: 8px;
      tr {
        .th {
          padding: 0 8px;

          &:first-child {
            padding-left: 20px !important;
          }
          &:last-child {
            // padding-right: 20px;
          }
        }
        .td {
          padding: 0 8px;

          &:first-child {
            padding: 0 20px !important;
          }
          &:last-child {
            // padding-right: 20px;
          }
        }
      }
      .textAreaContainer {
        padding-right: 0 !important;
      }
      .buttonContainer {
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 20px 0;
        justify-content: center;
        flex-wrap: wrap;
        row-gap: 8px;
      }
    }
  }
}
