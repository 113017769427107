.cardContainer {
  width: 100%;
  height: auto;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 24px;
  position: relative;

  .column {
    width: 25vw;
    margin: 5px;
  }

  .trackingText {
    color: rgb(240, 51, 30);
    font-size: 12px;
  }

  .cardDropDowns {
    display: flex;
    align-items: flex-start;
    padding: 0 50px;
    flex-wrap: wrap;
    /* height: 100px; */
    column-gap: 50px;
    flex-direction: column;
    align-content: flex-start;
  }

  .toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
  }

  .imageUpload {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 30px;
  }

  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 85px;
    padding-left: 50px;
    padding-right: 20px;
    border-bottom: 1px solid #eeeeee;

    .title {
      font-family: "Nunito Bold";
      color: #000;
      font-size: 24px;
    }
  }

  .textAreaContainer {
    width: 100%;
    padding: 0 50px;
    position: relative;

    .placeholder {
      position: absolute;
      display: flex;
      align-items: center;
      left: 70px;
      top: 20px;
      font-family: "Nunito Regular";
      color: #7b7b7b;
      column-gap: 15px;
    }

    textarea {
      width: 100%;
      height: 200px;
      outline: none;
      border: 1px solid #dedede;
      font-family: "Nunito Regular";
      font-size: 16px;
      color: #7b7b7b;
      border-radius: 5px;
      padding: 20px;
      resize: none;
      transition: all 0.3s ease-in-out;

      &:focus {
        border: 1px solid #3658cc;
        background-color: #f8f9fa;
      }
    }
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    column-gap: 20px;
    padding: 20px 50px;

    .uploadStatus {
      font-family: "Nunito Regular";
    }
  }

  .fileInfo {
    color: #555;
    font-size: 0.9rem;
    margin-top: 0.5rem;

    p {
      margin: 0.2rem 0;
    }
  }
}

.containerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 30px;
}

@media screen and (max-width: 768px) {
  .cardContainer {
    .cardDropDowns {
      padding: 0 20px;
      column-gap: 20px;
    }

    .cardHeader {
      padding-left: 20px;
      padding-right: 20px;
    }

    .textAreaContainer {
      padding: 0 20px;
    }

    .buttonContainer {
      padding: 20px 20px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .cardContainer {
    .column {
      width: 20vw;
      margin: 5px;
    }
    .toggle {
      width: 40vw;
    }
  }
}

@media screen and (max-width: 1440px) {
  .cardContainer {
    .column {
      width: 40vw;
      margin: 5px;
    }
  }
}
