.shortInputContainer {
  display: flex;

  align-items: center;

  .shortInputWrapper {
    display: flex;
    width: 200px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #e9ecef;
    border-radius: 5px;
    align-items: center;
    position: relative;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 12px;

    input {
      border: none;
      outline: none;
      width: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding: 0 10px 0 10px;
      background-color: transparent;
    }
  }
  .requiredMark {
    color: red;
    margin-right: 25%;
    margin-bottom: 20px;
    position: absolute;
  }
}

.imageUploadContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .imageUploadWrapper {
    display: flex;
    width: 200px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #e9ecef;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 12px;

    input {
      border: none;
      outline: none;
      width: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding: 0 10px 0 10px;
      background-color: transparent;
    }
  }

  .requiredMark {
    color: red;
    margin-left: 4px;
    margin-bottom: 15px;
    position: absolute;
  }
}

.shortInputContainer {
  display: flex;
  flex-direction: "row";
  justify-content: center;
  gap: 10px;
  margin: 20px;
}

.darkContainer {
  background-color: #6e757c !important;

  .timeSelect {
    color: #fff !important;
  }
}

@media screen and (max-width: 768px) {
  .dropDownContainer {
    max-width: 139px;
  }
}
