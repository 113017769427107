.cardContainer {
  width: 100%;
  height: auto;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 24px;
  position: relative;

  .cardDropDowns {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 30px;
  }

  .tableWrapper {
    position: relative;
  }

  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 85px;
    padding-left: 50px;
    padding-right: 20px;
    border-bottom: 1px solid #eeeeee;

    .title {
      font-family: "Nunito Bold";
      color: #000;
      font-size: 24px;
    }
  }
}

.containerWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 5;
  margin: 20px;
}

@media screen and (max-width: 768px) {
  .cardContainer {
    .cardDropDowns {
      padding: 0 20px;
      column-gap: 20px;
      height: auto;
      padding: 20px 20px;
      row-gap: 8px;
    }

    .cardHeader {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
